import React from 'react';
import { Container, Stack } from "react-bootstrap";
import './styles/internal-pages.css';
import './styles/index.scss';
import Seo from '../components/seo/seo';
import { Script } from "gatsby";
import { graphql } from "gatsby"

const faq = ({data}) => {

    return (
        <div className="background-theme">
            <Container className="internal-pages-common faq">
                <Stack className="pt-5 pb-5">
                    <h1 className="mb-4">{data.allDatoCmsFaqPage.nodes[0].title}</h1>
                    <div className="mt-4 accordion" id="accordionExample">
                        {
                            data.allDatoCmsFaqPage.nodes[0].faqs.map((faqs, index) =>
                                <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={"heading" + index}>
                                        <button className="accordion-button  collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-controls={"collapse" + index}>
                                            {faqs.question}
                                        </button>
                                    </h2>
                                    <div id={"collapse" + index} className="accordion-collapse collapse" aria-labelledby={"heading" + index} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            {faqs.response}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Stack>
            </Container>
        </div>
    );
}

export default faq;

export const Head = ({ data }) => (
    <>
        <Seo
            title="FAQ"
            description={data.allDatoCmsFaqPage.nodes[0].seo.description}
            image={data.allDatoCmsFaqPage.nodes[0].seo.image.url}
        />
        <Script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js" integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3" crossorigin="anonymous" />
        <Script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.min.js" integrity="sha384-IDwe1+LCz02ROU9k972gdyvl+AESN10+x7tBKgc9I5HFtuNz0wWnPclzo6p9vxnk" crossorigin="anonymous" />
    </>
)

export const query = graphql`
query FaqQuery {
    allDatoCmsFaqPage {
        nodes {
          seo {
              description
              title
              image {
                url
              }
           }
          slugPage {
            title
            slug
          }
          title
          faqs {
            question
            response
          }
        }
      }
  }
`